import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "styled-media-query"

const StyledImage = styled(Img)`
  width: 346px;
  height: 357px;
  left: -40px;
  top: 257px;
  pointer-events: none;

  ${media.greaterThan("1550px")`
    left: 12vw;
  `}

  ${media.lessThan("1220px")`
    left: -70px;
    top: 230px;
  `}

  ${media.lessThan("medium")`
    width: 173px;
    height: 178px;
    left: -40px;
    top: 166px;
  `}

  transition: left 1s cubic-bezier(0.22, 1, 0.36, 1);
`

const AboutX = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutX: file(relativePath: { eq: "graphic-target-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 346, maxHeight: 357) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <StyledImage
      fluid={data.aboutX.childImageSharp.fluid}
      style={{
        position: "absolute",
      }}
      alt="Pinkish purple X shape in the background."
    />
  )
}

export default AboutX
