import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

// Component

const LogoGrid = ({
  partners,
  basis = 33,
  width = 60,
  marginTop = 60,
  marginBottom = 80,
  marginMobileTop = 0,
  marginMobileBottom = 0,
  containerMarginMobileBottom = 0,
}) => {
  // Styles

  const PartnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    margin-top: ${marginTop}px;
    width: ${width}vw;
    margin-left: auto;
    margin-right: auto;

    ${media.lessThan("medium")`
    width: 80vw;
    margin-top: ${marginMobileTop}px;
    margin-bottom: ${containerMarginMobileBottom}px;
  `}
  `

  const PartnerProfile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-bottom: ${marginBottom}px;
    flex-basis: ${basis}%;

    ${media.lessThan("medium")`
    flex-basis: 40%;
    margin-bottom: ${marginMobileBottom}px;
  `}
  `
  const PartnerInnerProfile = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    width: 50%;
    height: auto;

    ${media.lessThan("medium")`
    justify-content: space-between;
    width: 70%;
  `}
  `

  const PartnerPhoto = styled.img`
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  `

  const PartnerLink = styled.a`
    &:hover {
      opacity: 0.8;
    }
  `

  // Methods

  const createPartners = collection => {
    const section = []
    for (let i = 0; i < collection.length; i += 1) {
      const collectionItem = collection[i]
      section.push(
        <PartnerProfile key={`partner-profile-${i}`}>
          <PartnerInnerProfile>
            <PartnerLink
              href={collectionItem.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PartnerPhoto
                src={collectionItem.photo}
                alt={collectionItem.name}
              />
            </PartnerLink>
          </PartnerInnerProfile>
        </PartnerProfile>
      )
    }
    return section
  }

  return <PartnerContainer>{createPartners(partners)}</PartnerContainer>
}

export default LogoGrid
