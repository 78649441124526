import React from "react"
import styled, { keyframes } from "styled-components"
import Fade from "react-reveal/Fade"
import media from "styled-media-query"
import SEO from "../components/core/seo"

import Contact from "../components/contact"
import Layout from "../components/core/layout"
import FixedBackground from "../components/elements/image-background-fixed"
import AboutX from "../components/elements/image-about-X"

import bioJavier from "../images/graphic-bio-javier.png"
import bioDan from "../images/graphic-bio-dan.png"
import bioEmily from "../images/graphic-bio-emily.png"
import bioAmanda from "../images/graphic-bio-amanda.png"

import PartnerGoldmanSachs from "../images/partners/graphic-partner-goldman-sachs.png"
import PartnerIHCC from "../images/partners/graphic-partner-ihcc.png"
import PartnerLatinx from "../images/partners/graphic-partner-latinx-incubator.png"

import AwardApple from "../images/awards/graphic-award-apple.png"
import AwardAnthem from "../images/awards/graphic-award-anthem.png"
import AwardCSSDesign from "../images/awards/graphic-award-css-design-awards.png"
import AwardWebby from "../images/awards/graphic-award-webby.png"
import AwardClutchIllinois from "../images/awards/graphic-award-clutch-illinois.png"
import AwardClutchTopApps from "../images/awards/graphic-award-clutch-apps.png"
import AwardClutchTopStrategy from "../images/awards/graphic-award-clutch-strategy.png"
import AwardClutchTopMarketing from "../images/awards/graphic-award-clutch-marketing.png"

import LinkedInIcon from "../images/social/icon-linkedin.png"
import LogoGrid from "../components/widgets/logoGrid"

const bios = [
  {
    photo: bioJavier,
    name: "Javier E. Otero",
    position: "CEO and Founder",
    linkedIn: "https://www.linkedin.com/in/javierenriqueotero/",
  },
  {
    photo: bioDan,
    name: "Dan Streeting",
    position: "Design Director",
    linkedIn: "https://www.linkedin.com/in/danstreeting/",
  },
  {
    photo: bioEmily,
    name: "Emily Pettinger",
    position: "Digital Designer",
    linkedIn: "https://www.linkedin.com/in/emilypettinger/",
  },
  {
    photo: bioAmanda,
    name: "Amanda Mendez",
    position: "Digital Developer",
    linkedIn: "https://www.linkedin.com/in/amanda-mendez1/",
  },
]

const partners = [
  {
    photo: PartnerGoldmanSachs,
    name: "Goldman Sachs 10,000 Small Businesses",
    link: "https://www.goldmansachs.com/citizenship/10000-small-businesses/US/",
  },
  {
    photo: PartnerIHCC,
    name: "Draftbit",
    link: "https://www.draftbit.com",
  },
  {
    photo: PartnerLatinx,
    name: "1871 Latinx Incubator",
    link: "https://ihccbusiness.net/ltntech/",
  },
]

const partnerServices = [
  {
    photo: AwardApple,
    name: "Apple Design Award 2013",
    link: "https://developer.apple.com/design/awards/",
  },
  {
    photo: AwardAnthem,
    name: "Anthem Awards",
    link: "https://www.anthemawards.com/",
  },
  {
    photo: AwardCSSDesign,
    name: "CSS Design Awards",
    link: "https://www.cssdesignawards.com/",
  },
  { photo: AwardWebby, name: "Webby Awards", link: "https://webbyawards.com/" },
  {
    photo: AwardClutchIllinois,
    name: "Clutch Top Company 2021",
    link: "https://clutch.co/",
  },
  {
    photo: AwardClutchTopApps,
    name: "Clutch Top App Development Company",
    link: "https://clutch.co/",
  },
  {
    photo: AwardClutchTopStrategy,
    name: "Clutch Top Digital Strategy Company",
    link: "https://clutch.co/",
  },
  {
    photo: AwardClutchTopMarketing,
    name: "Clutch Top Website & Marketing Company",
    link: "https://clutch.co/",
  },
]

// Animations

const growWidth = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
`

const moveIn = keyframes`
  from {
    margin-right:-200px;
  }

  to {
    margin-right:-20px;
  }
`

// Components

const IndexContainer = styled.div`
  overflow-x: hidden;
  width: 100vw;
  height: 100%;
`

const Headline = styled.div`
  font-family: "Roobert Bold";
  margin-top: 160px;
  font-size: 3.375rem;
  line-height: 3.4rem;
  white-space: nowrap;
  pointer-events: none;

  ${media.lessThan("1220px")`
    font-size: 42px;
    line-height: 1.1
    padding-right: 0px;
  `}

  ${media.lessThan("medium")`
    font-size: 20px;
    line-height: 1.1;
    margin-top: 100px;
    width: 200px;
  `}
`

const SectionHeader = styled.div`
  font-family: "Nitti Bold";
  margin-top: 65px;
  margin-bottom: 35px;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  `}
`

const SectionContent = styled.div`
  line-height: 1rem;
  width: 490px;
  margin-bottom: 40px;

  ${media.lessThan("medium")`
      width: 400px;
      max-width: 90%;
  `}
`

const SubSectionContent = styled.div`
  padding-left: 10vw;
  margin-top: 60px;

  ${media.lessThan("medium")`
        padding-left: 0px;
        padding-top: 0px;
        flex-grow: 0;
    `}
`

const SmallRule = styled.div`
  height: 1px;
  width: 80%;
  margin-top: 90px;
  margin-right: 15px;
  margin-bottom: 50px;
  background-color: #535663;
  transform-origin: left;
  animation: ${growWidth} 2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  ${media.lessThan("medium")`
    margin-top: 60px;
    width: 60vw;
    margin-right: 30px;
    transform-origin: left;
  `}
`

const HomeHorizontalRule = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 150px;
  margin-bottom: 50px;
  background-color: #535663;
  transform-origin: center;
  animation: ${growWidth} 2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  ${media.lessThan("medium")`
        
        margin-top: 50px;
    `}
`

const TeamProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0px;
  flex-basis: 25%;

  ${media.lessThan("medium")`
    flex-basis: 40%;
    margin-bottom: 0px;
  `}
`

const ProfileDescription = styled.div`
  width: 225px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  ${media.lessThan("medium")`
    width: 125px;
  `}
`

const Photo = styled.img`
  width: 225px;
  height: auto;

  ${media.lessThan("medium")`
    width: 125px;
  `}
`
const Name = styled.div`
  margin-top: -20px;
  font-family: "Nitti Bold";

  ${media.lessThan("medium")`
    font-size: 8px;
  `}
`

const Position = styled.div`
  margin-top: -8px;

  ${media.lessThan("medium")`
    margin-top: -12px;
    font-size: 8px;
  `}
`

const BannerYear = styled.div`
  position: absolute;
  font-family: "Roobert Bold";
  text-align: right;
  font-size: 100px;
  line-height: 5rem;
  margin-top: 180px;
  margin-right: -20px;
  right: 0;
  animation-delay: 4s;
  animation: ${moveIn} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  pointer-events: none;

  ${media.lessThan("1220px")`
    font-size: 70px;
    line-height: 3rem;
    margin-top: 240px;
  `}

  ${media.lessThan("medium")`
    font-size: 36px;
    line-height: 2rem;
    margin-right: -10px;
    margin-top: 90px;
  `}
`

const ReviewWidget = styled.div`
  width: 100%;
  height: 395px;
`

const MainContainer = styled.div`
  display: flex;
  align-items: flex-end;

  ${media.lessThan("medium")`
        flex-direction: column;
        align-items: flex-start;
    `}
`

const MainRight = styled.div`
  display: flex;
  padding-left: 80px;
  padding-top: 20px;
  flex-grow: 1;
  max-width: 50%;
  flex-direction: column;
  align-items: baseline;

  ${media.lessThan("medium")`
        padding-left: 0px;
        padding-top: 0px;
        flex-grow: 0;
        max-width: 90%;
        margin-bottom: 0px;
    `}
`

const MainLeft = styled.div`
  flex-grow: 1;
  max-width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan("medium")`
        padding-right: 0px;
        padding-bottom: 0px;
    `}
`

const LinkedInLogo = styled.img`
  padding-top: 12px;
  width: 20px;
`

const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 60px;
  margin-left: -150px;

  ${media.lessThan("medium")`
    justify-content: space-between;
    margin-left: 0px;
  `}
`

const About = () => {
  React.useEffect(() => {
    // add widget to end of body and run it
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://widget.clutch.co/static/js/widget.js"
    script.async = true
    document.body.appendChild(script)
    // run script
    script.onload = () => {
      // @ts-expect-error Apparently we have to manually do this!! 🗑️
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      window.CLUTCHCO.Init()
    }
    return () => {
      // @ts-expect-error Apparently we have to manually do this!! 🗑️
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      window.CLUTCHCO.Destroy()
      document.body.removeChild(script)
    }
  }, [])

  const currentYearString = new Date().getFullYear().toString()
  const createTeam = () => {
    const teamSection = []
    for (let i = 0; i < bios.length; i += 1) {
      const teamBio = bios[i]
      teamSection.push(
        <TeamProfile key={`team-profile-${i}`}>
          <Photo src={teamBio.photo} alt={teamBio.name} />
          <ProfileDescription>
            <Name>{teamBio.name}</Name>
            <Position>{teamBio.position}</Position>
            <a
              href={teamBio.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <LinkedInLogo src={LinkedInIcon} alt="LinkedIn" />
            </a>
          </ProfileDescription>
        </TeamProfile>
      )
    }
    return teamSection
  }

  return (
    <IndexContainer>
      <FixedBackground />
      <AboutX />
      <Layout activeSection="about">
        <BannerYear>
          <div>{currentYearString.substr(0, 2)}</div>
          <div>/{currentYearString.substr(2, 2)}</div>
        </BannerYear>
        <SEO
          title="About Futurehaus: Bringing innovation within reach™"
          description="Reinventing the way people and organizations interact with design & technology. We're your capable, trustworthy, and cross-functional partner that provides an end-to-end solution for digital product development and implementation."
        />

        <Headline>
          <Fade bottom cascade duration={1000} distance="20px">
            <div>Reinventing the way people and</div>
          </Fade>
          <Fade bottom cascade duration={1000} distance="20px">
            organizations interact with
          </Fade>
          <Fade bottom cascade duration={1000} distance="20px">
            design &amp; technology
          </Fade>
        </Headline>
        <SmallRule />
        <MainContainer>
          <div>
            <SectionHeader>Our Story</SectionHeader>
            <SectionContent>
              Futurehaus is a trailblazing digital studio based in Chicago,
              revolutionizing the way organizations engage with technology and
              design. We specialize in creating highly impactful apps, websites,
              and annual reports, backed by innovative strategies that ensure
              your digital presence resonates deeply with your audience.
              <br />
              <br />
              Our approach uniquely blends creative design, technological
              prowess, and strategic thinking to transform your ideas into
              digital experiences that not only stand out but also drive
              meaningful results. Futurehaus is your one-stop-shop in crafting
              digital solutions that are as effective as they are inspiring.
            </SectionContent>
          </div>
        </MainContainer>
        <HomeHorizontalRule />
        <Headline>Team</Headline>
        <SubSectionContent>
          <SectionContent>
            We’re the first minority-owned studio of its kind in Chicago. We
            strive for an inclusive culture and our diverse team brings a fresh
            perspective to every opportunity.
          </SectionContent>
          <TeamContainer>{createTeam()}</TeamContainer>
        </SubSectionContent>
        <HomeHorizontalRule />
        <Headline>Partners</Headline>
        <SubSectionContent>
          <SectionContent>
            Futurehaus is an integral member of the thriving tech scene in
            Chicago and collaborates with partners to contribute to the growth
            of our community.
          </SectionContent>
        </SubSectionContent>
        <LogoGrid partners={partners} />
        <HomeHorizontalRule />
        <Headline>Awards &amp; Distinctions</Headline>
        <SubSectionContent>
          <SectionContent>
            Futurehaus graduated from Goldman Sachs 10,000 Small Businesses &
            1871 LatinX Incubator in 2021. Our work for clients has received
            honors such as the Apple Design Award, Anthem Award, CSS Design
            Award, Webby Award, and Lester Wunderman Online Excellence. Our work
            has been featured in FastCompany, CNBC, Mashable, USAToday, Product
            Hunt and FWA.
          </SectionContent>
        </SubSectionContent>
        <LogoGrid partners={partnerServices} />
        <Headline>Reviews</Headline>
        <SubSectionContent>
          <SectionContent>
            At Futurehaus, we strive for quality over quantity and provide the
            highest standard of service for all our clients.
          </SectionContent>
        </SubSectionContent>
        <ReviewWidget
          className="clutch-widget"
          data-url="https://widget.clutch.co"
          data-widget-type={12}
          data-height={375}
          data-nofollow={+true}
          data-expandifr={+true}
          data-scale={100}
          data-reviews="2085024,1601159,1567022,1445804,1441451,1356902"
          data-clutchcompany-id={1159482}
        />
        <Contact small />
      </Layout>
    </IndexContainer>
  )
}

export default About
